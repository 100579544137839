import React, { useEffect, useState } from 'react';
import {Dropdown, Input, DropdownItem, DropdownToggle, DropdownMenu, Row, Col, TabPane, TabContent, UncontrolledDropdown, Button} from "reactstrap";
import { COLORS } from '../../utils/color';
import repopulate_panel from '../../utils/panel_repopulation';
import repopulate_panel_pop_up from '../../utils/panel_pop_up_repopulation';
import INavBar, { UncontrolledINavbar } from '../INavBar';
import { ChromePicker } from 'react-color';
import { send_analytics, send_analytics_panel } from '../../utils/send_analytics';


import './style.css';
import { Tooltip } from 'antd';
import MCD_ColorPicker from '../MCD_ColorPicker';

const MaterialDropdown = ({ material_json, update_view, parent, json_item, handle_ui_response, popup_panel, mode, search_string_panel, is_section }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [tabs, set_tabs] = useState(0);
    const [color, set_color] = useState('#000000')
    const [show_cp, set_show_cp] = useState(false)
    const [mat_temp, set_mat_temp] = useState(false)
    const [color_changed, set_color_changed] = useState(false);
    const [triggered_by, set_triggered_by] = useState({key: 'blur'})
    const [dd_top, set_dd_top] = useState(0)
    const [cp_top, set_cp_top] = useState(0)
    const [tabs_list, set_tabs_list] = useState([
		{
			id: 0,
			name: "Store Materials"
		},
		{
			id: 1,
			name: "User Created"
		},
        {
            id: 2,
            name: "SKU Parameters"
        }
	])

    useEffect(() => {
        let new_tabs_list = []
        let id = 0;
        if(material_json.store_materials && material_json.store_materials.length){
            new_tabs_list.push({id: id, name: "Store Materials"})
            id++;
        }
        if(material_json.user_defined_materials && material_json.user_defined_materials.length){
            new_tabs_list.push({id: id, name: "User Created"})
            id++;
        }
        if(material_json.sku_parameter_materials && material_json.sku_parameter_materials.length){
            new_tabs_list.push({id: id, name: "SKU Parameters"})
        }

        set_tabs_list(new_tabs_list)
    }, [material_json.store_materials, material_json.user_defined_materials, material_json.sku_parameter_materials])

    useEffect(() => {
        if(json_item.color_templates && json_item.color_templates.length){
            set_mat_temp(json_item.color_templates[0].id);
        }
    }, [json_item, json_item.color_templates])

    useEffect(() => {
        get_dd_menu_offset()
        if(json_item.color_dropdown_visiblity){
            if(!dropdownOpen){
                set_show_cp(false);
                if(material_json && material_json.active_material && material_json.active_material.id && material_json.route && mat_temp){
                    if(window.debug_mode){
                        window.add_debug_log("discard_element_finish_color(\"" + JSON.stringify({mat_id:material_json.active_material.id,route:material_json.route, material_template_id: mat_temp}).replace(/"/g, '\\"') + "\");" );
                    }
                    color_changed && window.Module.discard_element_finish_color(JSON.stringify({mat_id:material_json.active_material.id,route:material_json.route, material_template_id: mat_temp}))
                }
                set_color_changed(false);
            }

        }
            return () => {
                if(triggered_by.key == 'blur' && json_item && json_item.color_dropdown_visiblity && material_json && material_json.active_material && material_json.active_material.id && material_json.route && mat_temp){
                    if(window.debug_mode){
                        window.add_debug_log("discard_element_finish_color(\"" + JSON.stringify({mat_id:material_json.active_material.id,route:material_json.route, material_template_id: mat_temp}).replace(/"/g, '\\"') + "\");" );
                    }
                    window.Module.discard_element_finish_color(JSON.stringify({mat_id:material_json.active_material.id,route:material_json.route, material_template_id: mat_temp}))
                }
            }

    }, [dropdownOpen])

    useEffect(() => {
        get_cp_menu_offset()
    }, [show_cp])
    // const [rename_enabled, set_rename_enabled] = useState(false);

    // const populate_panel_search = async(search_string) => {
	// 	// var active_design = window.Module.get_active_design();
	// 	// var active_view = active_design.active_view;
	// 	var promise_resp;
	// 	if(window.Module){
	// 		try{
	// 			promise_resp = window.Module.populate_panel_search(JSON.stringify({"search": search_string}));
	// 		}
	// 		catch(err){
	// 			// alert.error("Something went wrong with search")
	// 			return
	// 		}
	// 	}
	
	// 	var ui_response = await window.Promisify(promise_resp);
	// 	handle_ui_response(ui_response);
	
	// 	update_view()
	
	// }	

    const handleColorChange = async(color, event) => {
        set_color_changed(true);
        // Devvrat - call your function with "color.hex" as the color user selected
        if(material_json && color && color.hex && material_json.route && mat_temp && material_json.active_material && material_json.active_material.id){
            if(window.debug_mode){
                var index = window.Module.get_material_index(material_json.active_material.id);
				window.add_debug_log("set_element_finish_color(\"" + color.hex + "\",false,\"" + material_json.route + "\",active_design->materials[" + index + "]->id,\"" + mat_temp + "\");" );
			}
            window.Module.set_element_finish_color(color.hex, false, material_json.route, material_json.active_material.id, mat_temp);
        }
        set_color(color.hex)
    }

    const handleMatChange = async(color,mat_id) => {
        if(material_json && color && material_json.route && mat_id && material_json.active_material && material_json.active_material.id){
            if(window.debug_mode){
                var index = window.Module.get_material_index(material_json.active_material.id);
				window.add_debug_log("set_element_finish_color(\"" + color + "\",false,\"" + material_json.route + "\",active_design->materials[" + index + "]->id,\"" + mat_id + "\");" );
			}
            window.Module.set_element_finish_color(color, false, material_json.route, material_json.active_material.id, mat_id);
        }
        set_mat_temp(mat_id)
    }

   
    const toggleNavs = (index) => {
		set_tabs(index)
	};

    
    const switch_material = async (mat_id) => {
        triggered_by.key = 'switch_material'
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.active_material_select_item_id = material_json.id;
        var onclick_params = {}
        onclick_params.panel_id = material_json.id
        onclick_params.sku_id = mat_id
        send_analytics_panel(json_item, parent)
        var final_params = material_json.pass_params_to_carousel_onclick?JSON.stringify(onclick_params):mat_id;
        if(window.debug_mode){
            window.add_debug_log(material_json.route + "(\"" + final_params.replace(/"/g, '\\"') + "\");");
        }
        var resp = await window.Promisify(window.Module[material_json.route](final_params));
        handle_ui_response(resp);
        if(popup_panel){
            repopulate_panel_pop_up();
        }else{
            repopulate_panel();
        }
		// mode == 'search' ? populate_panel_search(search_string_panel) :''
        update_view();

        active_design.delete();
        active_view.delete();
    }

    const on_apply_cp = (color, mat_id) => {
        // console.log("KPP apply triggered", dropdownOpen, material_json)
        set_color_changed(false)
        if(material_json && material_json.route && color && mat_id && material_json.active_material && material_json.active_material.id){
            if(window.debug_mode){
                var index = window.Module.get_material_index(material_json.active_material.id);
				window.add_debug_log("set_element_finish_color(\"" + color + "\",true,\"" + material_json.route + "\",active_design->materials[" + index + "]->id,\"" + mat_id + "\");" );
			}
            window.Module.set_element_finish_color(color, true, material_json.route, material_json.active_material.id, mat_id);
        }
        toggle_cp();
        triggered_by.key = 'apply'
        var analytics_data = {};
        analytics_data.category = "Material by Color Apply";
        analytics_data.element_type = window.Module.get_selection_element_type();
        send_analytics(analytics_data,true);
        
        // Devvratt pass color.hex as arg to your function
    }

    const on_discard_cp = () => {
        // console.log("KPP discard triggered", dropdownOpen, material_json)
        set_color_changed(false);
        triggered_by.key = 'discard';
        if(material_json && material_json.active_material && material_json.active_material.id && material_json.route && mat_temp){
            if(window.debug_mode){
                window.add_debug_log("discard_element_finish_color(\"" +JSON.stringify({mat_id:material_json.active_material.id,route:material_json.route, material_template_id: mat_temp}).replace(/"/g, '\\"')+ "\");" );
            }
            window.Module.discard_element_finish_color(JSON.stringify({mat_id:material_json.active_material.id,route:material_json.route, material_template_id: mat_temp}))
        }
        toggle_cp();

        var analytics_data = {};
        analytics_data.category = "Material by Color Discard";
        send_analytics(analytics_data,true);
    }

    const get_dd_menu_offset = () => {
		var dd = document.getElementById('material_dropdown_panel'+json_item.id);
        // console.log("KP dd is", dd.offset().top)
        if(dd)
		 set_dd_top(dd.offsetTop);
	}

    const get_cp_menu_offset = () => {
        var cp = document.getElementById('cp_dropdown' + json_item.id);
        // console.log("KP cp is", cp.offset().top);
        if(cp)
            set_cp_top(cp.offsetTop);
    }

    const toggle = () => setDropdownOpen(prevState => !prevState);
    const toggle_cp = () => set_show_cp(show_cp => {
        if((!show_cp)==true){
            var analytics_data = {};
            analytics_data.category = "Material by Color Opened";
            send_analytics(analytics_data,true);
        }
        return !show_cp;
    })

    return (
        <Row className='class_position_unset' style={{margin:0, width:'100%'}}>
            <Col className='class_position_unset' style={{padding:0, display:'flex', alignItems:'center'}}>
                {material_json.mixed || !((material_json.active_material && material_json.active_material.src) || (material_json.active_material && material_json.active_material.color)) ? (<div className='flex_center' style={{width:'30px',height:is_section ? '22px' : '24px', backgroundColor:'#E6E9EE', marginRight:'5px', border: '1px dashed #A7A8B2', color: '#A7A8B2'}}>+</div>):''}
                {!material_json.mixed && material_json.active_material && material_json.active_material.src?(<img className='inf-border' src={material_json.active_material.src} style={{width:'30px',height:is_section ? '22px' : '24px', backgroundColor:'beige', marginRight:'8px'}}/>):''}
                {!material_json.mixed && material_json.active_material && material_json.active_material.color?(<div className='inf-border' style={{width:'30px',height:is_section ? '22px' : '24px', backgroundColor:material_json.active_material.color, marginRight:'8px'}}/>):''}
                <Dropdown direction="up" className='class_position_unset' isOpen={dropdownOpen} style={{all:'unset', listStyle: 'none', flex: 1, minWidth: '0px'}} toggle={json_item && json_item.disabled?null:(e)=> {toggle();}}>
                    <DropdownToggle className='unset_boxshadow'  style={{display:'flex',flexFlow:'row', backgroundColor:'white',cursor:'pointer', color:'black', fontSize:'12px', padding:is_section ? '0px 10px' : '2px 10px', border:`${is_section ? '0.6px' : '1px'} solid ${json_item.border_color ? json_item.border_color : COLORS.panel_item_borders}`, alignItems:'center', height:is_section ? '22px' : '24px', width:'100%', justifyContent:'space-between', borderRadius:'2px'}}>
                        <div style={{display:'flex',flexFlow:'row',fontSize:'12px', width:'100%', flex: 1, minWidth: 0}}>
                            {/* {material_json.active_material && material_json.active_material.src?(<img src={material_json.active_material.src} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''} */}
                            {/* {material_json.active_material && material_json.active_material.color?(<div style={{width:'30px',height:'30px', backgroundColor:material_json.active_material.color, marginRight:'5px'}}/>):''} */}
                            <Tooltip title={json_item.error_text} placement='right' mouseEnterDelay={0.5}>
                                <div id={'material_dropdown_panel' + json_item.id} className='lines1_elipsis' title={material_json.active_material?material_json.active_material.name:''}>{material_json.mixed ? 'Mixed' : material_json.active_material?material_json.active_material.name ? material_json.active_material.name : "<No Material name>" :'Select Material'}</div>
                            </Tooltip>
                        </div>
                        <span style={{marginLeft: '8px', marginRight: '2px'}}><i className='fa fa-caret-down' /></span>
                    </DropdownToggle>
                    <DropdownMenu style={{maxWidth: '240px', padding: '6px'}} className={window.innerHeight - dd_top < 125 ? 'material_dropdown_menu_bottom' : 'material_dropdown_menu'} direction='right' container='body'>
                        {json_item.color_dropdown_visiblity ?
                        <UncontrolledDropdown isOpen={show_cp} style={{ marginBottom: '12px', borderBottom: '1px solid #C5C7CF'}}>
                            <DropdownToggle onClick={toggle_cp} style={{width: '100%', background: 'white', color: 'black', fontSize: '13px', border: 'none', outline: 'none', boxShadow: 'none'}}>
                            <div id={'cp_dropdown' + json_item.id} style={{width:'100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '-4px'}}><span style={{paddingRight: '10px', color: '#212529'}}>Add Material from Color</span> <i className='fa fa-caret-right'></i></div>
                            </DropdownToggle>
                            <DropdownMenu style={{minWidth: '200px'}} right className={window.innerHeight - cp_top < 450 ? 'mcd_dd_pos_bottom' : 'mcd_dd_pos'}>
                                <div style={{}} onClick={(e) => e.stopPropagation()}>
                                    <MCD_ColorPicker color={color} handleChange={handleColorChange} toggle_dropdown={() => {}} customize_json={{hide_presets: true, toggle_text: 'Add Custom Color', trigger_onChangeComplete: true, default_state: true, always_open: true}}/>
                                    <div style={{padding: '0 12px 12px 12px', fontSize: '12px'}}>
                                        <div style={{marginBottom: '4px', borderTop: '1px solid #C5C7CF', paddingTop: '12px'}}>Finish</div>
                                        {json_item.color_templates ?
                                        <Input size='sm' style={{fontSize: '12px'}} type='select' onChange = {(e) => {handleMatChange(color,e.target.value)}}>
										{
											json_item.color_templates.map((item, idx) => (
												<option key={idx} value={item.id}>{item.name}</option>
											))
										} 
									    </Input> : ''}
                                    </div>
                                    <div style={{padding: '0 12px 10px 12px', display: 'flex', justifyContent: 'space-between'}}>
                                        <button onClick={on_discard_cp} className='secondary_button_default'>Discard</button>
                                        <button onClick={() => on_apply_cp(color, mat_temp)} className='primary_button_default'>Apply</button>
                                    </div>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown> : ''}

                        <UncontrolledINavbar tabs={tabs_list.map(item => item.name)} style_tabs={{gap: '12px'}} style_container={{padding: '0 6px'}} set_active_tab={set_tabs} active_color='#086BDA' custom_classname='inf_nav_tabs_blue_background' active_border_bottom={true} active_tab_border='2px solid #0078FF'/>
                        <TabContent activeTab={"tabs" + tabs} style={{display: 'flex', flexDirection: 'row', gap: '5px', overflow: 'auto', width: '100%', maxHeight: '200px', padding: '0'}}>
                            {material_json && material_json.store_materials && material_json.store_materials.length && tabs_list && tabs_list.find(item => item.name == 'Store Materials') ?
                            <TabPane tabId={"tabs" + tabs_list.find(item => item.name == 'Store Materials').id} style={{position: 'relative', width: '100%'}}>
                            {material_json.store_materials.map((mat,idx) => (
                                <DropdownItem style={{listStyle: 'none', paddingLeft: '6px', paddingRight: '6px'}} onClick={() => {switch_material(mat.id)}} key={idx}>
                                    <div style={{display:'flex',flexFlow:'row',fontSize:'13px'}}>
                                        {mat.src?(<img src={mat.src} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''}
                                        {mat.color?(<div style={{width:'30px',height:'30px', backgroundColor:mat.color, marginRight:'5px'}}/>):''}
                                        <div style={{paddingTop:'5px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{mat.name}</div>
                                    </div>
                                </DropdownItem>
                                ))}
                            </TabPane> : ''}

                            {material_json && material_json.user_defined_materials && material_json.user_defined_materials.length && tabs_list && tabs_list.find(item => item.name == 'User Created') ?
                            <TabPane tabId={"tabs" + tabs_list.find(item => item.name == 'User Created').id} className = "full-height" style={{width: '100%'}}>
                                {material_json.user_defined_materials.map((mat,idx) => (
                                <DropdownItem style={{listStyle: 'none', paddingLeft: '6px', paddingRight: '6px'}} onClick={() => {switch_material(mat.id)}} key={idx}>
                                    <div style={{display:'flex',flexFlow:'row',fontSize:'13px'}}>
                                        {mat.src?(<img src={mat.src} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''}
                                        {mat.color?(<div style={{width:'30px',height:'30px', backgroundColor:mat.color, marginRight:'5px'}}/>):''}
                                        <div style={{paddingTop:'5px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{mat.name}</div>
                                    </div>
                                </DropdownItem>
                                ))}
                            </TabPane> : ''}
                            
                            {material_json && material_json.sku_parameter_materials && material_json.sku_parameter_materials.length && tabs_list && tabs_list.find(item => item.name == 'SKU Parameters') ?
                            <TabPane tabId={"tabs" + tabs_list.find(item => item.name == 'SKU Parameters').id} className = "full-height" style={{width: '100%'}}>
                                {material_json.sku_parameter_materials.map((mat,idx) => (
                                    <DropdownItem style={{listStyle: 'none', paddingLeft: '6px', paddingRight: '6px'}} onClick={() => {switch_material(mat.id)}} key={idx}>
                                        <div style={{display:'flex',flexFlow:'row',fontSize:'13px'}}>
                                            {mat.src?(<img src={mat.src} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''}
                                            {mat.color?(<div style={{width:'30px',height:'30px', backgroundColor:mat.color, marginRight:'5px'}}/>):''}
                                            <div style={{paddingTop:'5px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{mat.name}</div>
                                        </div>
                                    </DropdownItem>
                                ))}
                                {/* {material_json.user_defined_materials.map((mat,idx) => (
                                <DropdownItem style={{listStyle: 'none'}} onClick={() => {switch_material(mat.id)}} key={idx}>
                                    <div style={{display:'flex',flexFlow:'row',fontSize:'13px'}}>
                                        {mat.src?(<img src={mat.src} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''}
                                        {mat.color?(<div style={{width:'30px',height:'30px', backgroundColor:mat.color, marginRight:'5px'}}/>):''}
                                        <div style={{paddingTop:'5px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{mat.name}</div>
                                    </div>
                                </DropdownItem>
                                ))} */}
                            </TabPane> : ''}
                        </TabContent>
                    </DropdownMenu>
                </Dropdown>
                
            </Col>
        </Row>
    );
}


export default MaterialDropdown;

